import React from 'react'
import styles from './Preview.module.scss'
import Spinner from '../spinner/Spinner'
import { useTranslation } from 'react-i18next'

const Preview = (props: { thumbnail: string }): JSX.Element => {
    const [t] = useTranslation()
    return (
        <div className={styles.container}>
            <p className={styles.title}>
                {t('detectingMeasuresAndTolerances')}
            </p>
            <p className={styles.subtitle}>{t('pleaseWaitAMoment')}</p>
            <div className={styles.uploadBox}>
                <Spinner />
                <p className={styles.loading}>{t('previewWaitAfewSeconds')}</p>
                <img src={props.thumbnail} className={styles.preview} />
            </div>
        </div>
    )
}

export default Preview
