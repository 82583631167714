import React from 'react'
import { SUCCESS_REDIRECT_URL } from '../constants/general.constants'
import styles from './Redirect.module.scss'

import { useTranslation } from 'react-i18next'

const Redirect = (): JSX.Element => {
    const [t] = useTranslation()

    const handleRedirect = (): any => {
        window.location.href = SUCCESS_REDIRECT_URL
        return null
    }

    return (
        <div className={styles.container}>
            <p className={styles.redirectText}>{t('followThisLink')}</p>
            <div className={styles.button} onClick={handleRedirect}>
                <p className={styles.buttonTitle}>{t('goToOrdering')}</p>
            </div>
        </div>
    )
}

export default Redirect
