/* eslint-disable no-useless-escape */
export const SUCCESS = 'success'
export const WARNING = 'warning'

export const VALID_TYPES = ['image/jpeg', 'image/png', 'application/pdf']

export const UNSUPPORTED_FILES = 'Unsupported file format.'
export const UNEXPECTED_ERROR = 'Error. Please try again.'
export const TOO_MANY_FILES =
    'Too many files uploaded. Retry by uploading one file.'

export const validateFile = (file: File): boolean => {
    if (VALID_TYPES.indexOf(file.type) === -1) {
        return false
    }
    return true
}

export const getBase64 = async (file: File): Promise<string> => {
    return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        reader.onload = function () {
            resolve(reader.result as string)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
    })
}

export const UPLOAD_COMPONENT = 'UPLOAD_COMPONENT'
export const RESULT_COMPONENT = 'RESULT_COMPONENT'
export const ERROR_COMPONENT = 'ERROR_COMPONENT'
export const LOADING_COMPONENT = 'LOADING_COMPONENT'
export const PREVIEW_COMPONENT = 'PREVIEW_COMPONENT'

// errors

interface ErrorMapping {
    [propertyName: string]: string
}

export const ERRORS: ErrorMapping = {
    DRAWING_RESOLUTION_TOO_LOW: 'DRAWING_RESOLUTION_TOO_LOW',
    DRAWING_FILE_FORMAT_UNSUPPORTED: 'DRAWING_FILE_FORMAT_UNSUPPORTED',
    DRAWING_FILE_SIZE_TOO_LARGE: 'DRAWING_FILE_SIZE_TOO_LARGE',
    DRAWING_NOISE_TOO_HIGH: 'DRAWING_NOISE_TOO_HIGH',
    DRAWING_CONTENT_NOT_UNDERSTOOD: 'DRAWING_CONTENT_NOT_UNDERSTOOD',
}

export const SECTION_FONT = '40px Arial'
export const SECTION_CIRCLE_SIZE = 30
export const SECTION_LINE_COLOR = 'red'
export const SECTION_HIGHLIGHTED_CIRCLE_COLOR = 'darkorange'
export const SECTION_HIGHLIGHTED_CIRCLE_SIZE = 40
export const CIRCLE_HIGHLIGHT_TIME = 700
export const SECTION_CIRCLE_COLOR = 'darkorange'
export const SECTION_FONT_COLOR = 'white'

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
export const PHONE_REGEX = /(\(?([\d \-\)\–\+\/\(]+)\)?([ .\-–\/]?)([\d]+))/
export const SUCCESS_REDIRECT_URL =
    'https://www.igus.de/info/cnc-bearbeitung-online'

export const CONTACT_COUNTRIES = [
    { key: 'GERMANY', label: 'Germany' },
    { key: 'AUSTRIA', label: 'Austria' },
    { key: 'FRANCE', label: 'France' },
    { key: 'SWITZERLAND', label: 'Switzerland' },
    { key: 'UNITED_KINGDOM', label: 'United Kingdom' },
]
