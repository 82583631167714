import React, { useState, useEffect } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import styles from './ErrorPage.module.scss'
import './carousel.scss'
import warning from '../../assets/warning.svg'
import { IError } from '../../interfaces/IError'
import { useTranslation } from 'react-i18next'
import Email from '../email/Email'

const ErrorPage = (props: {
    errors: IError[]
    thumbnail: string
}): JSX.Element => {
    const [display, setDisplay] = useState(true)
    const [t] = useTranslation()
    useEffect(() => {
        setTimeout(() => {
            setDisplay(false)
        }, 2000)
    }, [])

    return (
        <div className={styles.container}>
            <p className={styles.title}>{t('sorry')}</p>
            <p className={styles.subtitle}>{t('dueToTechinicalLimitation')}</p>
            <div className={styles.carouselContainer}>
                <img
                    className={styles.check}
                    src={warning}
                    style={{ display: display ? 'flex' : 'none' }}
                />
                <div className={styles.imageContainer} key={1}>
                    <img src={props.thumbnail} className={styles.image} />
                </div>
            </div>
            <div className={styles.nextContainer}>
                <p className={styles.next}>{t('whatsNextError')}</p>
                <p className={styles.nextInfo}>{t('contactSalesTeam')}</p>
                <Email />
            </div>
        </div>
    )
}

export default ErrorPage
