import React, { Dispatch, SetStateAction, useState } from 'react'
import styles from './Legend.module.scss'
import { IBlurb, ISelectedAlternative } from '../../interfaces/IBlurb'
import LegendRow from './Legend-Row'

const Legend = (props: {
    blurbs: IBlurb[]
    onHighlight: Dispatch<SetStateAction<number>>
    onSelectAlternative: Dispatch<SetStateAction<any>>
}): JSX.Element => {
    return (
        <div className={styles.container}>
            <table>
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Original</th>
                        <th>Alternative</th>
                        <th>Lock</th>
                    </tr>
                </thead>
                <tbody>
                    {props.blurbs.map((blurb: IBlurb) => (
                        <LegendRow
                            key={blurb.index}
                            blurb={blurb}
                            onHighlight={(index) => props.onHighlight(index)}
                            onSelectAlternative={props.onSelectAlternative}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default Legend
