import React, { useState } from 'react'
import styles from './Dashboard.module.scss'
import TopBar from '../top-bar/TopBar'
import StepperWrapper from '../stepper/StepperWrapper'
import BottomBar from '../bottom-bar/BottomBar'

const Dashboard = (): JSX.Element => {
    const [step, setStep] = useState(1)

    return (
        <div className={styles.container}>
            <TopBar step={step} setStep={setStep}></TopBar>
            <StepperWrapper step={step} setStep={setStep} />
            <BottomBar />
        </div>
    )
}

export default Dashboard
