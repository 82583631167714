import { IGdts } from './IGdts'
import { IMeasure } from './IMeasure'
import { BlurbType, IBlurb } from './IBlurb'
import { IRoughness } from './IRoughness'
import { IGeneralTolerance } from './IGeneralTolerances'

export interface ISection {
    payload_bytes: string
    sectional_id: string
    gdts: IGdts[]
    measures: IMeasure[]
    roughnesses: IRoughness[]
    general_tolerances: IGeneralTolerance[]
}

export const getBlurbs = (section: ISection): IBlurb[] => {
    const blurbs: IBlurb[] = [
        ...section.measures.map((measure: IMeasure, index: number) => {
            return {
                index,
                original: measure.original,
                alternatives: measure.alternatives,
                sectional_id: section.sectional_id,
                type: BlurbType.MEASURES,
            }
        }),
        ...section.gdts.map((gdts: IGdts, index: number) => {
            return {
                index: index + section.measures.length,
                original: gdts.original,
                alternatives: gdts.alternatives,
                sectional_id: section.sectional_id,
                type: BlurbType.IGDTS,
            }
        }),
        ...section.roughnesses.map((roughness: IRoughness, index: number) => {
            return {
                index: index + section.measures.length + section.gdts.length,
                original: roughness.original,
                alternatives: roughness.alternatives,
                sectional_id: section.sectional_id,
                type: BlurbType.ROUGHNESS,
            }
        }),
        ...section.general_tolerances.map(
            (generalTolerance: IGeneralTolerance, index: number) => {
                return {
                    index:
                        index +
                        section.measures.length +
                        section.gdts.length +
                        section.roughnesses.length,
                    original: generalTolerance.original,
                    alternatives: generalTolerance.alternatives,
                    sectional_id: section.sectional_id,
                    type: BlurbType.GENERAL_TOLERANCES,
                }
            }
        ),
    ]
    return blurbs
}
