import React from 'react'
import styles from './BottomBar.module.scss'
import { useTranslation } from 'react-i18next'

const BottomBar = (): JSX.Element => {
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <div className={styles.infoBar}>
                <div className={styles.infoItem}>
                    <a
                        href="https://www.igus.de/info/company-imprint-de?POP=yes"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('print')}
                    </a>
                </div>
                <hr />
                <div className={styles.infoItem}>
                    <a
                        href="https://www.igus.de/r05/staticContent/dse/dse_DEde.htm"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('dataProtection')}
                    </a>
                </div>
            </div>
        </div>
    )
}

export default BottomBar
