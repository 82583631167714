import React, { useState, useEffect } from 'react'
import styles from './Result.module.scss'
import checkmark from '../../assets/checkmark.svg'
import warning from '../../assets/warning.svg'
import { Carousel } from 'react-responsive-carousel'
import Section from '../section/Section'
import Legend from '../legend/Legend'
import { IBlurb, ISelectedAlternative } from '../../interfaces/IBlurb'
import { getBlurbs, ISection } from '../../interfaces/ISection'
import Email from '../email/Email'
import Redirect from '../redirect/Redirect'
import { useTranslation } from 'react-i18next'

const Result = (props: {
    sections: ISection[]
    result: boolean
}): JSX.Element => {
    const [t] = useTranslation()
    const res = props.result ? true : false
    const [display, setDisplay] = useState(true)
    const [sections, setSections] = useState(
        props.result
            ? props.sections
            : props.sections.filter(
                  (s) =>
                      s.gdts.length > 0 ||
                      s.measures.length > 0 ||
                      s.roughnesses.length > 0 ||
                      s.general_tolerances.length > 0
              )
    )
    const [slideIndex, setSlideIndex] = useState(0)
    const [blurbs, setBlurbs] = useState([] as IBlurb[])
    const [refresh, setRefresh] = useState(null)
    const [selectedAlternatives, setSelectedAlternatives] = useState(
        [] as ISelectedAlternative[]
    )

    useEffect(() => {
        setBlurbs(getBlurbs(sections[slideIndex]))
    }, [slideIndex])

    // Get first selections.
    useEffect(() => {
        sections.forEach((section: ISection) => {
            const sectionBlurbs = getBlurbs(section)

            sectionBlurbs.forEach((blurb) => {
                if (blurb.alternatives.length > 0) {
                    onSelectAlternative({
                        sectional_id: sections[slideIndex].sectional_id,
                        object_id: blurb.original.object_id,
                        alternative_id: blurb.alternatives[0].object_id,
                    })
                }
            })
        })
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setDisplay(false)
        }, 2000)
    }, [])

    const highlight = (index: any) => {
        setRefresh({ index, slideIndex })
    }

    const onSelectAlternative = (alternative: ISelectedAlternative) => {
        // Check if an alternative is already selected for a measure and remove it.
        setSelectedAlternatives((alternatives) => [
            ...alternatives.filter(
                (a) => a.object_id !== alternative.object_id
            ),
            alternative,
        ])
    }

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <p className={styles.title}>
                    {res ? t('greatWeCanProduce') : t('attention')}
                </p>
                <p className={styles.subtitle}>
                    {res ? t('notSure') : t('cannotProduce')}
                </p>

                <div
                    className={`${styles.outerContainer} ${
                        props.result ? styles.success : ''
                    }`}
                >
                    <div className={styles.carouselContainer}>
                        <img
                            className={styles.check}
                            src={props.result ? checkmark : warning}
                            style={{ display: display ? 'flex' : 'none' }}
                        />
                        <Carousel
                            autoPlay={false}
                            showArrows={true}
                            showStatus={false}
                            showThumbs={false}
                            className={styles.carousel}
                            axis={'horizontal'}
                            swipeable={true}
                            emulateTouch={true}
                            onChange={(index) => setSlideIndex(index)}
                        >
                            {sections.map(
                                (section: ISection, index: number) => (
                                    <div
                                        className={styles.imageContainer}
                                        key={section.sectional_id}
                                    >
                                        <Section
                                            section={section}
                                            index={index}
                                            refresh={refresh}
                                        />
                                    </div>
                                )
                            )}
                        </Carousel>
                    </div>
                    {blurbs.length > 0 && (
                        <Legend
                            blurbs={blurbs}
                            onHighlight={highlight}
                            onSelectAlternative={onSelectAlternative}
                        />
                    )}
                </div>
            </div>
            <div className={styles.nextContainer}>
                <p className={styles.next}>
                    {res ? t('whatsNextSuccess') : t('whatsNextError')}
                </p>
                <p className={styles.nextInfo}>
                    {res ? t('whatsNextTextSuccess') : t('whatsNextTextError')}
                </p>
                <Email selectedAlternatives={selectedAlternatives} />
                <Redirect />
            </div>
        </div>
    )
}

export default Result
