import React, { useEffect, useRef, useState } from 'react'
import styles from './Section.module.scss'
import { ISection } from '../../interfaces/ISection'
import {
    SECTION_LINE_COLOR,
    SECTION_CIRCLE_SIZE,
    SECTION_FONT,
    SECTION_FONT_COLOR,
    SECTION_HIGHLIGHTED_CIRCLE_COLOR,
    CIRCLE_HIGHLIGHT_TIME,
    SECTION_HIGHLIGHTED_CIRCLE_SIZE,
} from '../constants/general.constants'
import { IMeasure } from '../../interfaces/IMeasure'
import { IGdts } from '../../interfaces/IGdts'
import polylabel from 'polylabel'
import { IRoughness } from '../../interfaces/IRoughness'

const Section = (props: {
    section: ISection
    index: number
    refresh: any
}): JSX.Element => {
    const canvasRef = useRef()
    const [image, setImage] = useState(null)
    const [ctx, setCtx] = useState(null)

    useEffect(() => {
        if (props.refresh && props.refresh.slideIndex == props.index) {
            highlight(props.refresh.index)
        }
    }, [props.refresh])

    useEffect(() => {
        const canvas: HTMLCanvasElement = canvasRef.current
        const ctx = canvas.getContext('2d')
        const image = new Image()
        image.src = props.section.payload_bytes
        image.onload = function () {
            setImage(image)
            canvas.width = image.width
            canvas.height = image.height
            draw(ctx, image)
        }
    }, [])

    const draw = (ctx: CanvasRenderingContext2D, image: any) => {
        ctx.clearRect(0, 0, image.width, image.height)
        ctx.drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            0,
            image.width,
            image.height
        )

        props.section.measures.forEach((s: IMeasure, index: number) => {
            if (s.original) {
                index = index + 1
                ctx.beginPath()

                // Draw the line
                const x1 = s.original.position.sectional[0].x * image.width
                const y1 = s.original.position.sectional[0].y * image.height
                const x2 = s.original.position.sectional[1].x * image.width
                const y2 = s.original.position.sectional[1].y * image.height

                ctx.moveTo(x1, y1)
                ctx.lineTo(x2, y2)
                ctx.strokeStyle = SECTION_LINE_COLOR
                ctx.lineWidth = 5
                ctx.stroke()

                const circleCentre = getCircleCentre(
                    s.original.position.sectional,
                    image
                )
                drawCircle(ctx, circleCentre[0], circleCentre[1])

                drawNumber(ctx, circleCentre[0], circleCentre[1], index)
            }
        })

        props.section.gdts.forEach((s: IGdts, index: number) => {
            if (s.original) {
                index = index + 1 + props.section.measures.length || 0
                ctx.beginPath()

                // Draw the polygon
                // Get the first coordonates and place the path on it.
                const x1 = s.original.position.sectional[0].x * image.width
                const y1 = s.original.position.sectional[0].y * image.height
                ctx.moveTo(x1, y1)

                for (const line of s.original.position.sectional) {
                    ctx.lineTo(line.x * image.width, line.y * image.height)
                }
                ctx.lineTo(x1, y1)
                ctx.strokeStyle = SECTION_LINE_COLOR
                ctx.lineWidth = 5
                ctx.stroke()
                ctx.closePath()

                const circleCentre = getCircleCentre(
                    s.original.position.sectional,
                    image
                )

                drawCircle(ctx, circleCentre[0], circleCentre[1])

                drawNumber(ctx, circleCentre[0], circleCentre[1], index)
            }
        })

        props.section.roughnesses.forEach((s: IRoughness, index: number) => {
            if (s.original) {
                index =
                    index +
                        1 +
                        props.section.measures.length +
                        props.section.gdts.length || 0
                ctx.beginPath()

                // Draw the polygon
                // Get the first coordonates and place the path on it.
                const x1 = s.original.position.sectional[0].x * image.width
                const y1 = s.original.position.sectional[0].y * image.height
                // ctx.moveTo(x1, y1)

                for (const line of s.original.position.sectional) {
                    ctx.lineTo(line.x * image.width, line.y * image.height)
                }

                ctx.strokeStyle = SECTION_LINE_COLOR
                ctx.lineWidth = 5
                ctx.stroke()
                ctx.closePath()

                const circleCentre = getCircleCentre(
                    s.original.position.sectional,
                    image
                )

                drawCircle(ctx, circleCentre[0], circleCentre[1])

                drawNumber(ctx, circleCentre[0], circleCentre[1], index)
            }
        })

        setCtx(ctx)
    }

    const highlight = (index: number) => {
        const elements = [
            ...props.section.measures,
            ...props.section.gdts,
            ...props.section.roughnesses,
        ]
        const elementHighlighted = elements[index]

        const circleCentre = getCircleCentre(
            elementHighlighted.original.position.sectional,
            image
        )

        drawCircle(
            ctx,
            circleCentre[0],
            circleCentre[1],
            SECTION_HIGHLIGHTED_CIRCLE_COLOR,
            SECTION_HIGHLIGHTED_CIRCLE_SIZE
        )
        drawNumber(ctx, circleCentre[0], circleCentre[1], index + 1)

        setTimeout(() => {
            draw(ctx, image)
        }, CIRCLE_HIGHLIGHT_TIME)
    }

    const getCircleCentre = (
        sectional: any,
        image: HTMLImageElement
    ): number[] => {
        if (sectional.length > 4) {
            const polygon = sectional.map((point: any) => {
                return [point.x * image.width, point.y * image.height]
            })

            // Get the centre of the polygon using polylabel (https://github.com/mapbox/polylabel)
            // Notice that it needs an array of an array of arrays.
            return polylabel([polygon])
        } else {
            const x1 = sectional[0].x * image.width
            const y1 = sectional[0].y * image.height
            const x2 = sectional[1].x * image.width
            const y2 = sectional[1].y * image.height
            const circleX = x1 + (x2 - x1) * 0.5
            const circleY = y1 + (y2 - y1) * 0.5
            return [circleX, circleY]
        }
    }

    const drawCircle = (
        ctx: CanvasRenderingContext2D,
        circleX: number,
        circleY: number,
        color: string = SECTION_LINE_COLOR,
        circleSize: number = SECTION_CIRCLE_SIZE
    ) => {
        ctx.beginPath()
        ctx.moveTo(circleX, circleY)
        ctx.arc(circleX, circleY, circleSize, 0, 2 * Math.PI)
        ctx.fillStyle = color
        ctx.fill()
        ctx.closePath()
    }

    const drawNumber = (
        ctx: CanvasRenderingContext2D,
        circleX: number,
        circleY: number,
        index: number
    ) => {
        ctx.beginPath()
        ctx.font = SECTION_FONT
        ctx.fillStyle = SECTION_FONT_COLOR
        ctx.fillText(
            index.toString(),
            circleX - ctx.measureText(index.toString()).width / 2,
            circleY + 13
        )
        ctx.closePath()
    }

    return (
        <div className={styles.container}>
            <canvas id="canvas" ref={canvasRef}></canvas>
        </div>
    )
}

export default Section
