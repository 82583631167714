import React, {
    useState,
    useEffect,
    SetStateAction,
    Dispatch,
    useRef,
} from 'react'
import styles from './StepperWrapper.module.scss'
import Uploader from '../uploader/Uploader'
import Loading from '../loading/Loading'
import Preview from '../preview/Preview'
import Result from '../result/Result'
import {
    UPLOAD_COMPONENT,
    RESULT_COMPONENT,
    ERROR_COMPONENT,
    LOADING_COMPONENT,
    PREVIEW_COMPONENT,
    getBase64,
} from '../constants/general.constants'
import ErrorPage from '../error/ErrorPage'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { ISection } from '../../interfaces/ISection'
import { IMapping } from '../../interfaces/IMapping'
import { IMeasure } from '../../interfaces/IMeasure'
import { IGdts } from '../../interfaces/IGdts'
import { createContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IRoughness } from '../../interfaces/IRoughness'
import { IGeneralTolerance } from '../../interfaces/IGeneralTolerances'

const StepperWrapper = (props: {
    step: number
    setStep: Dispatch<SetStateAction<number>>
}): JSX.Element => {
    const [currentComponent, setCurrentComponent] = useState(PREVIEW_COMPONENT)
    const [uploadedFile, setUploadedFile] = useState(null)
    const [thumbnail, setThumbnail] = useState('')
    const [fileType, setFileType] = useState('')
    const [isFeasible, setIsFeasible] = useState(true)
    const [errors, setErrors] = useState(null)
    const [sections, setSections] = useState([] as ISection[])
    const interval = useRef(null)
    const [t] = useTranslation()
    const [generalTolerances, setGeneralTolerances] = useState([])

    const { sendMessage, lastMessage, readyState } = useWebSocket(
        process.env.REACT_APP_SOCKET_URL,
        {
            share: true,
        }
    )

    useEffect(() => {
        if (lastMessage) {
            const message = JSON.parse(lastMessage?.data)
            if (message.exceptions && message.exceptions.length > 0) {
                setErrors(message.exceptions)
                setCurrentComponent(ERROR_COMPONENT)
            } else {
                switch (message.message_subtype) {
                    case 'PAGE_THUMBNAIL': {
                        setThumbnail(
                            `data:${fileType};base64,${message.payload_bytes}`
                        )
                        break
                    }
                    case 'VARIANT_MEASURES': {
                        if (message.payload_dict.measures.length > 0) {
                            const feasible =
                                message.payload_dict.measures.findIndex(
                                    (e: IMeasure) => e.is_feasible === false
                                ) > -1
                                    ? false
                                    : true
                            const section = sections.find(
                                (s: ISection) =>
                                    s.sectional_id ==
                                    message.payload_dict.sectional_id
                            )
                            section.measures = [
                                ...message.payload_dict.measures,
                            ]
                            setIsFeasible(feasible)
                        }
                        break
                    }
                    case 'VARIANT_GDTS': {
                        if (message.payload_dict.gdts.length > 0) {
                            const feasible =
                                message.payload_dict.gdts.findIndex(
                                    (e: IGdts) => e.is_feasible === false
                                ) > -1
                                    ? false
                                    : true
                            const section = sections.find(
                                (s: ISection) =>
                                    s.sectional_id ===
                                    message.payload_dict.sectional_id
                            )
                            section.gdts = [...message.payload_dict.gdts]
                            setIsFeasible(feasible)
                        }
                        break
                    }
                    case 'VARIANT_ROUGHNESSES': {
                        if (message.payload_dict.roughnesses.length > 0) {
                            const feasible =
                                message.payload_dict.roughnesses.findIndex(
                                    (e: IRoughness) => e.is_feasible === false
                                ) > -1
                                    ? false
                                    : true
                            const section = sections.find(
                                (s: ISection) =>
                                    s.sectional_id ===
                                    message.payload_dict.sectional_id
                            )
                            section.roughnesses = [
                                ...message.payload_dict.roughnesses,
                            ]
                            setIsFeasible(feasible)
                        }
                        break
                    }
                    case 'TITLE_BLOCK': {
                        if (message.payload_dict.general_tolerances) {
                            const feasible =
                                message.payload_dict.general_tolerances
                                    .is_feasible
                            // general tolerances are attached to the first sectional
                            // it isn't an array
                            if (!feasible) {
                                setGeneralTolerances([
                                    message.payload_dict.general_tolerances,
                                ])
                                setIsFeasible(feasible)
                            }
                        }
                        break
                    }
                    case 'SECTIONAL_THUMBNAIL': {
                        if (message.payload_dict) {
                            const newSection: ISection = {
                                sectional_id: message.payload_dict.sectional_id,
                                payload_bytes: `data:${fileType};base64,${message.payload_bytes}`,
                                measures: [] as IMeasure[],
                                gdts: [] as IGdts[],
                                roughnesses: [] as IRoughness[],
                                general_tolerances: [] as IGeneralTolerance[],
                            }
                            setSections((s: ISection[]) => [...s, newSection])
                        }
                        break
                    }
                    case 'COMPLETED': {
                        if (currentComponent === PREVIEW_COMPONENT) {
                            if (generalTolerances) {
                                const firstSection = sections.filter(
                                    (s) =>
                                        s.gdts.length > 0 ||
                                        s.measures.length > 0 ||
                                        s.roughnesses.length > 0
                                )[0]
                                if (firstSection) {
                                    firstSection.general_tolerances =
                                        generalTolerances
                                }
                            }
                            setCurrentComponent(RESULT_COMPONENT)
                        }
                        break
                    }
                }
            }
        }
    }, [readyState, lastMessage])

    useEffect(() => {
        if (thumbnail) {
            setCurrentComponent(PREVIEW_COMPONENT)
            props.setStep(2)
        }
    }, [thumbnail])

    useEffect(() => {
        if (readyState === 1 && !interval.current) {
            interval.current = setInterval(() => {
                sendMessage(
                    JSON.stringify({ command: 'ping', drawing_bytes: '' })
                )
            }, parseInt(process.env.REACT_APP_PING_TIME) || 45000)
        } else if (readyState === 2 || readyState === 3) {
            alert(t('lostConnection'))
        }
    }, [readyState])

    useEffect(() => {
        if (props.step === 1) {
            setCurrentComponent(UPLOAD_COMPONENT)
            setUploadedFile(null)
            setThumbnail('')
            setFileType('')
            setIsFeasible(true)
            setErrors(null)
            setSections([])
        }
    }, [props.step])

    const updateFile = async (file: File) => {
        setFileType(file.type)
        const base64: string = await getBase64(file)
        setUploadedFile(base64)
        const stripped = base64.split(`data:${file.type};base64,`)[1]
        sendMessage(
            JSON.stringify({ command: 'read', drawing_bytes: stripped })
        )
    }

    const components: IMapping = {
        [UPLOAD_COMPONENT]: (
            <Uploader
                updateComponent={setCurrentComponent}
                updateFile={updateFile}
            />
        ),
        [LOADING_COMPONENT]: <Loading />,
        [PREVIEW_COMPONENT]: <Preview thumbnail={thumbnail} />,
        [RESULT_COMPONENT]: <Result sections={sections} result={isFeasible} />,
        [ERROR_COMPONENT]: (
            <ErrorPage thumbnail={uploadedFile} errors={errors} />
        ),
    }

    return (
        <div className={styles.container}>{components[currentComponent]}</div>
    )
}

export default StepperWrapper
