import React, { Dispatch, SetStateAction } from 'react'
import styles from './TopBar.module.scss'
import logo from '../../assets/logo.svg'
import { useTranslation } from 'react-i18next'
import { languageNames } from '../../internationalization/i18n'
import level1 from '../../assets/level1.svg'
import level2 from '../../assets/level2.svg'

const TopBar = (props: {
    step: number
    setStep: Dispatch<SetStateAction<number>>
}): JSX.Element => {
    const { t, i18n } = useTranslation()

    const onLogoClick = () => {
        window.location.href = 'https://igus.com'
    }

    const onChangeLanguage = (event: any) => {
        const languageSelected = event.target.value
        i18n.changeLanguage(languageSelected)
    }

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <div className={styles.logoBar} onClick={onLogoClick}>
                    <img src={logo} className={styles.logo} />
                </div>
                <div className={styles.language}>
                    <select onChange={onChangeLanguage}>
                        {languageNames.map((language) => (
                            <option key={language.key} value={language.key}>
                                {language.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className={styles.stepBar}>
                <div
                    className={`${styles.step} ${
                        props.step === 1 ? styles.active : ''
                    }`}
                    onClick={() => props.setStep(1)}
                >
                    <img src={level1}></img> {t('step1')}
                </div>
                <hr />
                <div
                    className={`${styles.step} ${
                        props.step === 2 ? styles.active : ''
                    }`}
                >
                    <img src={level2}></img> {t('step2')}
                </div>
            </div>
        </div>
    )
}

export default TopBar
