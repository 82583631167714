import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import deTranslation from './de/de.json'
import enTranslation from './en/en.json'
import frTranslation from './fr/fr.json'
import itTranslation from './it/it.json'
import svTranslation from './sv/sv.json'
import nlTranslation from './nl/nl.json'
import daTranslation from './da/da.json'
import csTranslation from './cs/cs.json'
import ptTranslation from './pt/pt.json'
import roTranslation from './ro/ro.json'

export const languageNames = [
    { key: 'de', name: '🇩🇪 German' },
    { key: 'en', name: '🇺🇸 English' },
    { key: 'fr', name: '🇫🇷 French' },
    { key: 'it', name: '🇮🇹 Italian' },
    { key: 'sv', name: '🇸🇪 Swedish' },
    { key: 'nl', name: '🇳🇱 Dutch' },
    { key: 'da', name: '🇩🇰 Danish' },
    { key: 'cs', name: '🇨🇿 Czech' },
    { key: 'pt', name: '🇵🇹 Portuguese' },
    { key: 'ro', name: '🇷🇴 Romanian' },
]

export const languages = {
    de: {
        translations: deTranslation,
    },
    en: {
        translations: enTranslation,
    },
    fr: {
        translations: frTranslation,
    },
    it: {
        translations: itTranslation,
    },
    sv: {
        translations: svTranslation,
    },
    nl: {
        translations: nlTranslation,
    },
    da: {
        translations: daTranslation,
    },
    cs: {
        translations: csTranslation,
    },
    pt: {
        translations: ptTranslation,
    },
    ro: {
        translations: roTranslation,
    },
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: languages,
        lng: 'de',
        fallbackLng: ['en', 'de'],
        debug: false,
        defaultNS: 'translations',
        ns: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

export default i18n
