import React, { useState, useRef, Dispatch } from 'react'
import styles from './Uploader.module.scss'
import upload from '../../assets/upload.svg'
import {
    VALID_TYPES,
    TOO_MANY_FILES,
    UNSUPPORTED_FILES,
    UNEXPECTED_ERROR,
    validateFile,
    LOADING_COMPONENT,
} from '../constants/general.constants'
import { useTranslation } from 'react-i18next'

const Uploader = (props: {
    updateFile: any
    updateComponent: Dispatch<React.SetStateAction<string>>
}): JSX.Element => {
    const [uploadHover, setUploadHover] = useState(false)
    const [error, setError] = useState('')
    const hiddenFileInput = useRef(null)
    const [t] = useTranslation()

    const handleDragLeave = (e: any) => {
        setUploadHover(false)
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDragOver = (e: any) => {
        setUploadHover(true)
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDrop = (e: any) => {
        setUploadHover(false)
        if (e.dataTransfer.files.length > 1) {
            setError(TOO_MANY_FILES)
        } else {
            const droppedFile = e.dataTransfer.files[0]
            if (droppedFile && validateFile(droppedFile)) {
                uploadFile(droppedFile)
                setError('')
            } else {
                setError(UNSUPPORTED_FILES)
            }
        }
        e.preventDefault()
        e.stopPropagation()
    }

    const uploadFile = (file: File) => {
        props.updateFile(file)
        props.updateComponent(LOADING_COMPONENT)
    }

    const handleChooseFileClick = () => {
        hiddenFileInput.current.click()
    }

    const handleInputUpload = (e: any) => {
        const uploaded = e.target.files[0]
        if (uploaded && validateFile(uploaded)) {
            uploadFile(uploaded)
            setError('')
        } else {
            setError(UNEXPECTED_ERROR)
        }
    }

    return (
        <div className={styles.container}>
            <div>
                <p className={styles.title}>
                    {t('productionFeasibilityCheck')}
                </p>
                <p className={styles.subtitle}>
                    {t('producitonFeasibilityCheckIndication')}
                </p>
            </div>
            <hr />
            <p className={styles.uploadTitle}>{t('uploadTechnicalDrawing')}</p>

            <div
                className={`${styles.uploadBox} ${
                    uploadHover ? styles.uploadHover : ''
                }`}
                onDrop={(e) => handleDrop(e)}
                onDragOver={(e) => handleDragOver(e)}
                onDragLeave={(e) => handleDragLeave(e)}
            >
                {/* <p className={styles.uploadTitle}>
                    {t('uploadTechnicalDrawing')}
                </p> */}

                <div className={styles.uploadActions}>
                    <img
                        src={upload}
                        alt="upload"
                        className={styles.uploadImage}
                    />
                    <p className={styles.dnd}>{t('dragAndDrop')}</p>
                    <p className={styles.or}> - {t('or')} - </p>
                    <div
                        className={styles.button}
                        onClick={handleChooseFileClick}
                    >
                        <p className={styles.buttonTitle}>{t('chooseFile')}</p>
                    </div>
                    <input
                        type="file"
                        accept={VALID_TYPES.join(', ')}
                        style={{ display: 'none' }}
                        ref={hiddenFileInput}
                        onChange={handleInputUpload}
                    />
                    <p className={styles.error}>{error}</p>
                    <p className={styles.dataFormats}>{t('dataFormat')}</p>
                </div>
                <p className={styles.firstPageOnly}>{t('firstPageOnly')}</p>
            </div>
        </div>
    )
}

export default Uploader
