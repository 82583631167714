import React, { Dispatch, SetStateAction, useState } from 'react'
import styles from './Legend.module.scss'
import {
    BlurbType,
    IBlurb,
    ISelectedAlternative,
} from '../../interfaces/IBlurb'
import { useTranslation } from 'react-i18next'

const MANUAL_CHECK_REQUIRED = 'manual_check_required'

const LegendRow = (props: {
    blurb: IBlurb
    onHighlight: Dispatch<SetStateAction<number>>
    onSelectAlternative: Dispatch<SetStateAction<ISelectedAlternative>>
}): JSX.Element => {
    const [lock, setLock] = useState(false)
    const [t] = useTranslation()

    const onLock = (event: any) => {
        setLock(event.target.checked)
        props.onHighlight(props.blurb.index)
    }

    const highlight = () => {
        if (props.blurb.type !== BlurbType.GENERAL_TOLERANCES) {
            props.onHighlight(props.blurb.index)
        }
    }

    const selectAlternative = (event: any) => {
        props.onHighlight(props.blurb.index)
        props.onSelectAlternative({
            sectional_id: props.blurb.sectional_id,
            object_id: props.blurb.original.object_id,
            alternative_id: event.target.value,
        })
    }

    const displayBlurb = (blurbLabel: any, type: BlurbType): string => {
        switch (type) {
            case BlurbType.IGDTS: {
                return blurbLabel.frame.blurb as string
            }
            case BlurbType.MEASURES: {
                return blurbLabel.label.blurb as string
            }
            case BlurbType.ROUGHNESS: {
                return blurbLabel.label.blurb.replace(
                    `{${MANUAL_CHECK_REQUIRED}}`,
                    t(MANUAL_CHECK_REQUIRED)
                ) as string
            }
            case BlurbType.GENERAL_TOLERANCES: {
                return blurbLabel.blurb as string
            }
        }
    }

    return (
        <tr key={props.blurb.index}>
            <td onClick={highlight}>
                <p className={styles.bubble}>{props.blurb.index + 1}</p>
            </td>
            <td onClick={highlight}>
                {displayBlurb(props.blurb.original, props.blurb.type)}
            </td>
            <td className={styles.alternatives}>
                {props.blurb.alternatives.length > 1 ? (
                    <select disabled={lock} onChange={selectAlternative}>
                        {props.blurb.alternatives?.map((alternative, index) => (
                            <option key={index} value={alternative?.object_id}>
                                {displayBlurb(alternative, props.blurb.type)}
                            </option>
                        ))}
                    </select>
                ) : props.blurb.alternatives.length == 1 ? (
                    displayBlurb(props.blurb.alternatives[0], props.blurb.type)
                ) : (
                    '-'
                )}
            </td>
            <td className={styles.lock}>
                <input
                    type="checkbox"
                    value={props.blurb.index}
                    onChange={onLock}
                ></input>
            </td>
        </tr>
    )
}

export default LegendRow
