import React, { Suspense } from 'react'
import styles from './App.module.scss'
import Dashboard from './components/dashboard/Dashboard'
import './internationalization/i18n'

const App = (): JSX.Element => {
    return (
        <div className={styles.container}>
            <Suspense fallback={'Loading...'}>
                <Dashboard />
            </Suspense>
        </div>
    )
}

export default App
