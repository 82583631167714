import React, { useState, useEffect } from 'react'
import {
    CONTACT_COUNTRIES,
    EMAIL_REGEX,
    ERRORS,
    PHONE_REGEX,
} from '../constants/general.constants'
import styles from './Email.module.scss'
import checkmark from '../../assets/checkmark.svg'
import warning from '../../assets/warning.svg'
import { useTranslation } from 'react-i18next'
import useWebSocket from 'react-use-websocket'
import { ISelectedAlternative } from '../../interfaces/IBlurb'

const Email = (props: {
    selectedAlternatives?: ISelectedAlternative[]
}): JSX.Element => {
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [message, setMessage] = useState('')
    const [emailCountry, setEmailCountry] = useState(CONTACT_COUNTRIES[0].key)
    const [download, setDownload] = useState(true)
    const [contact, setContact] = useState(true)
    const [success, setSuccess] = useState(false)
    const [backendErrors, setBackendErrors] = useState([] as string[])
    const [t] = useTranslation()

    // Get shared connection
    const { sendMessage, lastMessage, readyState } = useWebSocket(
        process.env.REACT_APP_SOCKET_URL,
        {
            share: true,
        }
    )

    const onChangeEmailCountry = (event: any) => {
        setEmailCountry(event.target.value)
    }

    const onSubmit = () => {
        // Check connection before sending the message.
        if (readyState == 2 || readyState == 3) {
            setBackendErrors([t('genericBackendError')])
            return
        }

        if (isFormValid(email, phoneNumber)) {

            if (download) {
                sendMessage(
                    JSON.stringify({
                        command: 'get_summary_pdf',
                        email_address: email,
                        email_name: `${firstName} ${lastName}`,
                        email_message: message,
                        phone_number: phoneNumber,
                        selected_alternatives: props.selectedAlternatives,
                        request_sales_call: contact
                    })
                )
            }

            if (contact && !download) {
                sendMessage(
                    JSON.stringify({
                        command: 'send_email_to_sales',
                        email_address: email,
                        email_name: `${firstName} ${lastName}`,
                        email_message: message,
                        phone_number: phoneNumber,
                        selected_alternatives: props.selectedAlternatives,
                        email_country: emailCountry,
                        request_sales_call: false
                    })
                )
            }
        }
    }

    const isFormValid = (email: string, phoneNumber: string): boolean => {
        // reset the errors
        setEmailError('')
        setPhoneNumberError('')
        let success = true

        if (!EMAIL_REGEX.test(email)) {
            setEmailError(t('emailInputError'))
            success = false
        }

        if (phoneNumber && !PHONE_REGEX.test(phoneNumber)) {
            setPhoneNumberError(t('phoneNumberError'))
            success = false
        }

        return success
    }

    useEffect(() => {
        if (lastMessage) {
            const message = JSON.parse(lastMessage?.data)
            switch (message.message_subtype) {
                case 'SUCCESS': {
                    switch (message.message_type) {
                        case 'GET_SUMMARY_PDF': {
                            setSuccess(true)
                            const linkSource = `data:application/pdf;base64,${message.payload_bytes}`
                            const downloadLink = document.createElement('a')
                            downloadLink.href = linkSource
                            downloadLink.download = 'summary.pdf'
                            downloadLink.click()
                            break
                        }
                        case 'SEND_EMAIL_TO_SALES': {
                            setSuccess(true)
                            // Might need to do something in the future here.
                            break
                        }
                    }

                    break
                }
                case 'ERROR': {
                    setBackendErrors([t('genericBackendError')])
                    setSuccess(false)
                    switch (message.message_type) {
                        case 'GET_SUMMARY_PDF': {
                            setBackendErrors((errors) => [
                                ...errors,
                                t('summaryError'),
                            ])
                            break
                        }
                        case 'SEND_EMAIL_TO_SALES': {
                            setBackendErrors((errors) => [
                                ...errors,
                                t('emailError'),
                            ])
                            break
                        }
                    }
                    break
                }
                default: {
                    setBackendErrors([t('genericBackendError')])
                }
            }
        }
    }, [lastMessage])

    return (
        <div className={styles.container}>
            {!success && !backendErrors.length && (
                <React.Fragment>
                    <div className={styles.inputs}>
                        <div className={styles.inputContainer}>
                            <label
                                className={`${styles.label}  ${styles.required}`}
                                htmlFor="email"
                            >
                                {t('email')}
                            </label>
                            <input
                                onChange={(e) => setEmail(e.target.value)}
                                className={styles.input}
                                value={email}
                                id="email"
                            />
                            <p className={styles.inputError}>{emailError}</p>
                        </div>
                        <div className={styles.inputContainer}>
                            <label className={styles.label} htmlFor="firstName">
                                {t('firstName')}
                            </label>
                            <input
                                onChange={(e) => setFirstName(e.target.value)}
                                className={styles.input}
                                value={firstName}
                                id="firstName"
                                maxLength={30}
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <label className={styles.label} htmlFor="lastName">
                                {t('lastName')}
                            </label>
                            <input
                                onChange={(e) => setLastName(e.target.value)}
                                className={styles.input}
                                value={lastName}
                                id="lastName"
                                maxLength={30}
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <label
                                className={styles.label}
                                htmlFor="phoneNumber"
                            >
                                {t('phoneNumber')}
                            </label>
                            <input
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                className={styles.input}
                                value={phoneNumber}
                                id="phoneNumber"
                                maxLength={20}
                            />
                            <p className={styles.inputError}>
                                {phoneNumberError}
                            </p>
                        </div>
                    </div>
                    <div className={styles.actions}>
                        <div className={`${styles.inputContainer}`}>
                            <label className={styles.label} htmlFor="message">
                                {t('email_country')}
                            </label>
                            <select
                                value={emailCountry}
                                className={styles.select}
                                onChange={onChangeEmailCountry}
                            >
                                {CONTACT_COUNTRIES.map((country, index) => (
                                    <option value={country.key} key={index}>
                                        {country.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div
                            className={`${styles.inputContainer} ${styles.inputContainerRelaxed}`}
                        >
                            <label className={styles.label} htmlFor="message">
                                {t('messageUs')}
                            </label>
                            <textarea
                                onChange={(e) => setMessage(e.target.value)}
                                className={styles.textarea}
                                id="message"
                                value={message}
                                rows={3}
                            />
                        </div>
                        <div className={styles.action}>
                            <input
                                onChange={(e) => setDownload(e.target.checked)}
                                type="checkbox"
                                id="download"
                                checked={download}
                            />
                            <label htmlFor="download">{t('download')}</label>
                        </div>
                        <div className={styles.action}>
                            <input
                                onChange={(e) => setContact(e.target.checked)}
                                type="checkbox"
                                id="sendEmail"
                                checked={contact}
                            />
                            <label htmlFor="sendEmail">
                                {t('getConnected')}
                            </label>
                        </div>
                        <div>
                            <div onClick={onSubmit} className={styles.button}>
                                <p className={styles.buttonTitle}>
                                    {t('submit')}
                                </p>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
            {success && (
                <React.Fragment>
                    <div className={styles.success}>
                        <img className={styles.check} src={checkmark} />
                        <p>{t('success')}</p>
                    </div>
                </React.Fragment>
            )}
            {backendErrors.length > 0 && (
                <React.Fragment>
                    <div className={styles.error}>
                        <img className={styles.warning} src={warning} />
                        <div className={styles.errorsContainer}>
                            {backendErrors.map((backendError, index) => (
                                <p key={index}>{backendError}</p>
                            ))}
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export default Email
