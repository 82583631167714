import React from 'react'
import styles from './Loading.module.scss'
import Spinner from '../spinner/Spinner'
import { useTranslation } from 'react-i18next'

const Loading = (): JSX.Element => {
    const [t] = useTranslation()
    return (
        <div className={styles.container}>
            <p className={styles.title}>{t('uploadingFile')}</p>
            <p className={styles.subtitle}>{t('youFileIsBeingUploaded')}</p>
            <div className={styles.uploadBox}>
                <Spinner />
                <p className={styles.loading}>{t('waitAFewSeconds')}</p>
            </div>
        </div>
    )
}

export default Loading
