export interface IBlurb {
    alternatives: any[]
    original: any
    index: number
    sectional_id: string
    type: BlurbType
}

export enum BlurbType {
    IGDTS = 'igdts',
    MEASURES = 'measures',
    ROUGHNESS = 'roughness',
    GENERAL_TOLERANCES = 'general_tolerances',
}

export interface ISelectedAlternative {
    sectional_id: string
    object_id: string
    alternative_id: string
}
